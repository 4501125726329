.product-review {
    display: flex;
    flex-direction: column;
    grid-gap: 16px;
    align-items: center;
    align-self: normal;
    margin-top: 24px;

    & .product-review__comment {
        resize: none;
        padding: 8px 16px;
        height: 100px;
    }
}