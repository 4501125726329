@import '../../less/variables.less';


.text p+p {
    margin-top: 0;
}

.text p:not(:last-child) {
    margin-bottom: 0;
}

.buttons {
    display: flex;
    gap: 16px;
    justify-content: center;
    flex-direction: column;
    align-self: stretch;

    @media(@desktop) {
        flex-direction: row;
    }
}