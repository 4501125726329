form {
    .has-error {
        label {
            color: @state-danger-text;
        }
    }

    input.form-control,
    textarea.form-control,
    select.form-control {
        .text-primary;
        border-radius: 4px;
        background-color: @color-white;
        border-width: 2px;
        border-style: solid;
        border-color: @color-grey-02;
        box-shadow: none;
        filter: none;

        &:hover {
            border-color: @color-iron;
        }

        &:focus {
            background-color: @color-squeeze;
            border-color: @color-mint-green;
            box-shadow: none;
        }

        &.error {
            background-color: @state-danger-bg;
            border-color: @state-danger-text;
        }
    }

    input.form-control,
    select.form-control {
        height: 50px;
    }

    select.form-control {
        background: @color-white url('../images/svg/icon-chevron-down.svg') right 12px center no-repeat;
    }

    .checkbox-container {
        padding: 16px;
        margin-bottom: 16px;

        border-radius: @border-radius;
        background-color: @color-narvik;
        border: 1px solid @color-mint-green;

        label {
            margin-bottom: 0;
            color: @color-swamp;
        }

        &.error {
            background-color: @state-danger-bg;
            border-color: @state-danger-border;
        }
    }

    div.error {
        padding-top: 8px;
        color: @state-danger-text;
        font-size: 16px;
    }
}

.form-control {
    display: block;
    width: 100%;
    padding: 4px 14px;
    font-size: 16px;
    color: @color-oxford;
    background-color: @color-white;
    border: 1px solid @color-oslo-grey;

    textarea& {
        padding: 8px 16px;
    }
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

.form-group {
    display: inline-block;
    width: 100%;
    margin-bottom: 24px;

    label,
    .label {
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        color: @color-oxford;
        display: block;
        margin-bottom: 8px;
    }

    &.small {
        input {
            width: 20%;
            min-width: 120px;
        }
    }

    &.small-group {
        width: 20%;
        min-width: 120px;
    }

    &.medium {
        width: 70%;
    }

    @media @from-medium {
        &.half-width {
            width: 50%;

            &.right {
                width: calc(~'50% - 10px');
                margin-left: 10px;
            }
        }
    }

    .plain-text {
        height: 48px;
        display: flex;
        align-items: center;
    }

    label:has(+ input:focus) {
        font-weight: bold;
    }

    label::after+input[required],
    label::before+input[aria-required],
    select[required]+label::before,
    select[aria-required]+label::before {
        margin-left: 8px;
        content: '*';
    }

    &.zip-code {
        display: flex;

        .form-group {
            margin-bottom: 0;
        }

        .half-width {
            width: 50%;
            margin-left: 24px;
        }
    }
}

.white {

    input.form-control,
    textarea.form-control {
        background-color: @color-grey-01;

        &:hover {
            border-color: @color-grey-03;
        }

        &:focus {
            background-color: @color-squeeze;
            border-color: @color-mint-green;
        }
    }
}

.form-title {
    text-align: center;
    margin-bottom: 16px;
    margin-top: 0;
    font-size: 22px;

    @media @from-large {
        font-size: 24px;
    }
}

// flex setup for each form-section
.form-section {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @media @from-medium {
        flex-direction: row;
    }

    .form-section-info {
        @media @to-medium {
            padding-bottom: 16px;
        }

        @media @from-medium {
            width: 35%;
            padding-right: 48px;
        }

        h2,
        h3 {
            .margin-bottom-tiny;
            margin-top: 0;
            font-size: 14px;

            @media @from-large {
                font-size: 16px;
            }
        }

        p {
            .text;
            .text-secondary;
        }
    }

    fieldset,
    .form-section-content {
        @media @from-medium {
            flex: 0 0 65%;
        }

        .form-group:last-of-type {
            margin-bottom: 0;
        }
    }

    &.form-section-checkbox {
        @media @from-medium {
            .form-section-info {
                width: 65%;
            }

            fieldset {
                flex: 0 0 35%;
            }
        }
    }

    &.medium {
        max-width: @medium-content-width;
        margin: 0 auto;

        .form-section-content {
            @media @from-medium {
                flex: 0 0 100%;
            }
        }
    }

    &.center {
        text-align: center;
    }

    .infobox-centered {
        margin-bottom: 16px;
    }
}

.form-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    margin-top: 32px;

    &.center {
        justify-content: center;
    }

    @media @from-medium {
        margin-top: 40px;
    }

    @media @from-xlarge {
        margin-top: 48px;
    }

    button {
        margin-left: 16px;
    }
}

.form-buttons-nomargin {
    .form-buttons;
    width: 100%;
    margin-top: 0 !important;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

input[type='number'] {
    -moz-appearance: textfield;
}

.formVmp {
    fieldset {
        legend {
            font-size: 16px;
            margin-bottom: 8px;
            padding-bottom: 4px;

            span {
                font-weight: normal;
            }
        }
    }

    .checkbox-container {
        padding: 16px;
        margin-bottom: 16px;

        border-radius: @border-radius;
        background-color: @color-narvik;
        border: 1px solid @color-mint-green;

        label {
            margin-bottom: 0;

            &::before {
                left: 16px;
            }
        }
    }

    .title {
        display: none;
    }

    p {
        margin-bottom: 15px;
    }

    @media @from-medium {
        button {
            width: auto;
        }
    }
}

.checkbox-wrapper {
    position: relative;
    display: flex;
    align-items: center;

    input[type='checkbox'] {
        appearance: none;
        margin: 0;
        padding: 0;
        width: 20px;
        height: 20px;
        border: 1px solid @color-oxford;
        border-radius: 4px;
        background: @color-white;
        cursor: pointer;
        flex: 0 0 auto;

        &:checked {
            background: @color-oxford url('../images/svg/checkbox-checked.svg') center no-repeat;
        }

        &:hover {
            border: 2px solid @color-oslo-grey;
        }

        +span {
            font-size: 14px;
            font-weight: normal;
            padding-left: 12px;
        }

        &[role='switch'] {
            --accent-color: @color-nevada;
            width: 36px;
            border-radius: 10px;
            background-image: radial-gradient(circle, var(--accent-color) 10px, transparent 10px);
            background-position-x: -10px;
            background-repeat: no-repeat;
            transition: background-position-x .1s;

            &:checked {
                background-position-x: 10px;
                --accent-color: @color-swamp;
            }
        }
    }
}

select {
    all: unset;
    box-sizing: inherit;
    font: inherit;
    width: 100%;
    font-size: 14px;
    line-height: 46px;
    color: @color-swamp;
    border-radius: @border-radius;
    border: 2px solid @color-grey-02;
    padding: 0 12px;
    position: relative;
    background: @color-white url('../images/svg/icon-chevron-down.svg') right 12px center no-repeat;
}

.form-magazine-action {
    display: flex;
    justify-content: center;
}

label {
    display: inline-block;
}

::-moz-placeholder,
::placeholder {
    color: @color-oslo-grey;
    opacity: 1; // Override Firefox's unusual default opacity;
}