.pagination {
    margin: 16px 0;
    display: grid;
    grid-template-columns: 42px max-content 42px;
    grid-template-rows: 42px;
    justify-content: center;
    align-items: center;
    gap: 16px;

    @media @from-small {
        gap: 32px;
    }

    .pagination-text {
        font-size: 14px;
        line-height: 120%;
    }

    .pagination-button {
        .btn--transparent;
        margin: 0;
        padding: 16px;
        color: @color-swamp;
        font-size: 12px;
    }
}