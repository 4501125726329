.my-list {
	ul {
		max-height: 270px;
		overflow-y: scroll;
	}

	.new-list h2 {
		.sub-headline;
		margin: 0 0 16px;
		text-align: left;
	}

	.new-list {
		margin-top: 16px;
		display: flex;
		flex-direction: column;

		.error-message {
			margin-bottom: 16px;
		}

		form {
			display: grid;
			grid-template-columns: minmax(200px, 500px) auto;
			grid-gap: 8px;
		}
	}

	.new-list-btn {
		border: none;
		align-self: end;
		display: flex;
		align-items: center;
		grid-gap: 8px;
	}
}

.my-list-item {
	background: @color-narvik;
	border-radius: 4px;

	&+.my-list-item {
		margin-top: 8px;
	}

	.checkbox-wrapper {
		padding: 16px;
	}
}

.my-list-item-favorite {
	.my-list-favorite-btn {
		background: @color-old-lace;
		font-size: 14px;
		padding: 16px;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: start;
		grid-gap: 16px;
		border-radius: 4px;

		&:hover>.icon-favorite {
			background-image: url(../images/svg/icon-favorite-selected.svg);
		}
	}
}

label {
	&:hover {
		cursor: pointer;
	}
}