.frontpage-article-module {
    .gridRowsForMobile(@rows) {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(@rows, auto);
    }

    .article-module__items {
        display: grid;
        grid-gap: 16px;

        &--title {
            .section-spacing;
        }

        @media (min-width: @screen-md) {
            grid-gap: 40px;
        }
    }

    @media (min-width: @screen-sm) {
        .article-module__items {
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: repeat(3, auto);
        }

        .articles-1 {
            .article-module__items {
                grid-template-columns: repeat(1, 1fr);
                grid-template-rows: repeat(1, auto);
            }
        }

        .articles-2, .articles-4 {
            .article-module__items {
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: repeat(2, auto);
            }
        }

        .articles-3 {
            .article-module__items {
                grid-template-rows: repeat(2, auto);
            }
            .article-card {
                &:first-child {
                    grid-column: ~"1 / 4";
                    grid-row: ~"1 / 3";
                }
                &:nth-child(3) {
                    grid-column: ~"4 / 4";
                    grid-row: ~"2 / 2";
                }
            }
        }

        .articles-5 {
            .article-card {
                &:first-child {
                    grid-column: ~"1 / 3";
                    grid-row: ~"1 / 1";
                }
                &:nth-child(2) {
                    grid-column: ~"3 / 5";
                    grid-row: ~"1 / 1";
                }
                &:nth-child(3) {
                    grid-column: ~"1 / 4";
                    grid-row: ~"2 / 5";
                }
            }
        }

        .articles-6 {
            .article-card {
                &:nth-child(5) {
                    grid-column: ~"1 / 3";
                    grid-row: ~"2 / 4";
                }
                &:nth-child(6) {
                    grid-column: ~"3 / 5";
                    grid-row: ~"2 / 4";
                }
            }
        }

        .articles-7, .articles-overflow {
            .article-card {
                &:nth-child(5) {
                    grid-column: ~"1 / 4";
                    grid-row: ~"2 / 5";
                }
            }
        }
    }

    .articles-5, .articles-7 {
        .article-card {
            &:last-child {
                .lastArticleInRow();
            }
        }
    }

    .articles-overflow {
        .article-card {
            &:nth-child(n + 7){
                .lastArticleInRow();
            }
        }
    }

    .lastArticleInRow() {
        margin-bottom: -16px;

        @media (min-width: @screen-md) {
            margin-bottom: -40px;
        }
    }

    .article-module__link {
        display: inline-block;
        position: absolute;
        font-weight: normal;
        font-size: 14px;
        bottom: 1px;
        right: 10px;
        &:hover, &:focus {
            text-decoration: none;
        }
        &:active {
            font-weight: bold;
        }
    }

    .article-module {
        padding: 0;
        &.articles-overflow {
            article {
                // viser ikke flere enn 7 artikler
                &:nth-child(n + 8) {
                    display: none;
                }
            }
        }

        /***************  One Article view mode ***********/
        @media @from-medium {
            // viser 1 i bredden med ingress
            &.articles-1 {
                article {
                    display: flex;

                    &:hover ~ a {
                        text-decoration: underline;
                    }

                    .article-card__image-container {
                        width: 50%;
                    }

                    .article-module__item__ingress {
                        padding: 24px 0;
                        font-size: 18px;
                    }

                    .article-card__info {
                        padding: 56px;
                        width: 50%;
                    }
                }
            }
        }
        & > :last-child {
            margin-bottom: 0;
        }
    }

    .category-home-page & {
        margin-bottom: 56px;

        @media (max-width: @screen-sm) {
            margin-bottom: 0;

            .article-card:last-child {
                margin-bottom: 32px;
            }

            .articles-overflow .article-card:nth-child(n + 7) {
                margin-bottom: 32px;
            }
        }
    }
}