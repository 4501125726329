@import '../../less/variables.less';

.confirm-email p {
    margin: 0;
    text-align: start;
    max-width: none;
}

.buttons {
    display: flex;
    flex-direction: column;
    padding-top: 32px;
    margin-left: 32px;

    >p+button {
        margin-top: 16px;
    }

    >button+p {
        margin-top: 32px;
    }
}