.checkout-page {
    @media @from-medium {
        .checkout-order-summary {
            top: 92px; // To offset the height of the navbar. Should find a more dynamic way of implementing this.
            position: sticky;
        }
    }

    .payment-infos {
        padding: 16px;
        background-color: @color-apple-green;
        border-top: 1px solid @color-grey-03;

        .payment-info-button {
            background-color: @color-white;
            text-align: left;
            width: 100%;
            font-size: 16px;
            display: block;

            margin-bottom: 8px;
            border: 1px solid @color-grey-03;
            border-radius: @border-radius;
            padding: 16px;

            &:last-of-type {
                margin-bottom: 0;
            }

            &:not(.payment-info--selected) {
                &:hover,
                &:focus {
                    background-color: @color-haze;
                }
            }
        }
    }

    .gift-card--used {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h3 {
            cursor: default;
            margin: 0;
        }
    }

    .giftcard-form {
        .error-message {
            margin: 5px 0;
        }

        .btn-primary {
            min-width: 160px;
            text-align: center;
        }

        .gift-card__number {
            min-width: 240px;
        }
    }
}

.payment-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    line-height: 140%;

    &.payment-info__type--new-card {
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 16px;
    }

    label {
        display: block;
        input {
            display: inline-block;
            margin-right: 20px;
            margin-left: 8px;
        }

        &.cardexpired {
            color: @color-iron;
        }
    }

    .btn {
        width: 100%;
        text-align: left;
        padding-left: 35px;
        color: @color-swamp;
    }

    &:hover,
    &:focus {
        text-decoration: none;
    }

    .payment-info__card-number {
        color: @color-swamp;
        font-weight: bold;
    }

    &.payment-info--selected {
        margin-top: 16px;
    }
}
