@import '../../less/variables.less';

.search {
  display: flex;
  width: 100px;
  align-items: center;
  position: relative;

  &.transition {
    transition: width .3s ease-in-out, margin-left .3s ease-in-out;
  }

  &:hover {

    button,
    input {
      color: @color-white;
    }
  }

  // When the searchbox has focus or a value
  &:focus-within,
  &:has(input:not(:placeholder-shown)) {
    width: 300px;

    @media @to-small {
      // On small screens we don't want it to cover the menu buttons
      // until it has focus, so we do some very hacky and manual math
      // There is a logo on the left and a shopping cart + hamburger menu on the right
      // The total width including padding of these three elements is 168px
      // Since there is more on the right than the left we need a negative margin-left
      width: ~'calc(100vw - 168px)';
      margin-left: -30px;

      &:focus-within:has(input:not(:placeholder-shown)) {
        // On small screens it should take up almost the entire width
        // This covers the menu buttons
        width: ~'calc(100vw - 20px)';
        margin-left: 0px;
      }
    }

    button {
      pointer-events: all;
      color: @color-nevada;
    }

    input {
      background-color: @color-white;
      color: @color-swamp;
    }
  }

  // Only show the suggetions when the search box has focus and has some text inside it
  &:focus-within:has(input:not(:placeholder-shown)) ul {
    display: block;
  }

  button {
    position: absolute;
    background: none;
    padding: 10px;
    pointer-events: none;
    color: @color-iron;
  }

  input {
    flex: 1 1 auto;
    width: 100%;
    padding-left: 44px;
    padding-right: 10px;
    background-color: @color-oxford;
    border-radius: 22px;
    min-height: 44px;
    border: none;
    font-size: 16px;
    color: @color-iron;
    text-overflow: ellipsis;

    // Don't show the search decorations.
    // We have our own magnifying icon
    // and we only want to show the clear-search-input button
    // when the search box has focus or some value
    &::-webkit-search-results-button,
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button {
      display: none;
    }

    &:focus,
    &:not(:placeholder-shown) {
      // prevent the focus ring
      box-shadow: none;

      // this is the clear-search-input button in webkit browsers
      &::-webkit-search-cancel-button {
        -webkit-appearance: none;
        display: block;
        height: 16px;
        width: 16px;
        mask-image: url(../../images/svg/icon-clear-search.svg);
        cursor: pointer;
        background-color: @color-nevada;
        opacity: 1;
        pointer-events: auto;

        &:hover {
          background-color: @color-swamp;
        }
      }
    }
  }

  // autocomplete
  ul {
    margin: 10px 0 0 0;
    padding: 0;
    width: 100%;
    top: 100%;
    left: 0;
    right: 0;
    position: absolute;
    background-color: @color-white;
    border-radius: 4px;
    z-index: 1;
    box-shadow: 0 0 8px #0004;
    display: none; //only show in .search:focus-within
    overflow: hidden;

    @media @to-small {
      // Make the box shadow bigger on small devices,
      // where the autocomplete takes up almost the entire width of the screen
      box-shadow: 0 0 16px 10px #0004;
    }

    li {
      line-height: 120%;
      color: @color-blue-stone;
      list-style: none;
      font-weight: bold;

      a {
        padding: 16px;
        display: block;

        &:hover {
          text-decoration: underline;
        }
      }

      &:hover,
      &.active {
        background-color: @color-narvik;
      }
    }
  }
}