.my-page {
    dl {
        dd:not(:last-child) {
            font-size: 18px;
            margin-bottom: 16px;
        }
    }

    dd {
        font-size: 16px;
    }

    form .form-buttons {
        margin: 16px;
    }

    .subscription-box {
        margin: 0 auto 48px;
        width: 100%;
        max-width: @medium-content-width;
        text-align: center;
        background: @color-white;

        padding: 16px;

        @media @from-medium {
            padding: 32px;
        }

        @media @from-xlarge {
            padding: 56px;
        }

        h4 {
            margin-bottom: 8px;
        }
    }

    .store-details {
        padding: 0;
    }

    .pagelist .form-buttons {
        @media @to-medium {
            margin-right: 16px;
        }
    }

    .order-details {
        &.main-side-columns {
            --side: 480px;
            gap: 24px;
        }

        .order-detail-info,
        .order-detail-summary {
            background-color: @color-white;
            padding: 24px;
        }

        .order-detail-info {
            display: flex;
            flex-direction: column;
            margin-bottom: 24px;

            .info-message {
                order: 1;
                margin: 0 0 16px 0;
            }

            .order-detail-value {
                order: 2;
                margin-top: 16px;
                display: flex;

                &:first-of-type {
                    margin-top: 0;
                }

                .label {
                    flex: 0 1 40%;
                    .text-secondary;
                    font-size: 14px;
                }

                .value {
                    flex: 0 1 60%;
                    .text-primary;
                    font-size: 16px;
                    font-weight: bold;
                    line-height: 120%;
                    white-space: pre-line;
                    display: flex;
                    flex-direction: column;

                    a {
                        width: max-content;
                    }
                }
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .card-list {
        list-style: none;
        margin-left: 0;
        padding-left: 0;
        margin-bottom: 24px;

        li {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            flex-wrap: wrap-reverse;
            padding: 24px;
            margin-bottom: 16px;
            border: 1px solid @color-grey-02;
            border-radius: 4px;
            background: @color-grey-01-5;

            &.selected {
                background-color: @color-narvik;
                border: 2px solid @color-mint-green;
            }

            &.expired {
                background-color: @color-wisp-pink;
                border: 2px solid @color-azalea;
            }
        }

        li:last-of-type {
            margin-bottom: 0;
        }
    }

    .pagelink__container {
        display: flex;
        gap: 16px;

        @media @to-medium {
            padding: 0 16px;
        }

        a {
            width: 100%;
            background-color: @color-old-lace;
            text-align: center;
            font-weight: bold;
            padding: 24px 0;
            border-radius: 4px;
            border: 1px solid @color-flesh;

            &:hover {
                border-bottom: 1px solid @color-flesh;

                span {
                    text-decoration: underline;
                }
            }

            .icon {
                margin: auto auto 16px auto;
            }

            span {
                .primary-label;
            }
        }
    }

    .notifications-list {
        .info-text {
            text-align: center;
            margin: 0 16px 16px;
        }

        .product__image-container img {
            margin: 0;
        }
    }
}

.address {
    line-height: 120%;

    .main {
        font-weight: bold;
    }
}