
.margin-bottom {
    margin-bottom: 16px;

    @media @from-xlarge {
        margin-bottom: 24px;
    }
}

.margin-bottom-none {
    margin-bottom: 0;
}

.margin-bottom-tiny {
    margin-bottom: 4px;
}

.margin-bottom-large {
    margin-bottom: 32px;

    @media @from-xlarge {
        margin-bottom: 56px;
    }
}