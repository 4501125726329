.message-box {
    padding: 16px;
    max-width: @medium-content-width;
    margin: 0 auto;
    border-radius: 4px;
    font-size: 16px;
    line-height: 140%;
    display: flex;
    text-align: left;
    align-items: center;

    @media @to-medium {
        margin: 16px;
    }

    .icon {
        flex-shrink: 0;
    }

    ul {
        padding-left: 20px;
        list-style: disc;
        margin-bottom: 0;
        margin-left: 10px;
    }

    .message-text {
        padding-left: 16px;
        overflow-wrap: break-word;
        overflow-x: auto;
    }
}

.error-message {
    .message-box;
    background-color: @state-danger-bg;
    color: @state-danger-text;

    &.margin-bottom {
        @media @to-small {
            margin: 0 0 16px 0;
        }
    }
}

.info-message {
    .message-box;
    background-color: @color-cream-brulee;
    border: none;
    max-width: none;

    &.margin-bottom {
        margin-bottom: 16px;
    }
}