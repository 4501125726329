/******************************************************************************/
/* VMP Theme                                                                  */
/******************************************************************************/

@import 'normalize.less';
@import 'variables.less';
@import 'fonts.less';
@import 'icons.less';
@import 'headings.less';
@import 'error-message.less';
@import 'spacing.less';
@import 'typography.less';
@import 'accessibility.less';
@import 'form.less';
@import 'site.less';
@import 'page.less';
@import 'page-header.less';
@import 'header-category-bar.less';
@import 'modal.less';
@import 'section.less';
@import 'menu-button.less';
@import 'footer.less';
@import 'article.less';
@import 'btn.less';
@import 'product.less';
@import 'product-list.less';
@import 'product-item.less';
@import 'product-review.less';
@import 'my-list.less';
@import 'pagination.less';
@import 'cart.less';
@import 'checkout.less';
@import 'store-locator.less';
@import 'store-info.less';
@import 'search-page.less';
@import 'wishlist-page.less';
@import 'related-articles.less';
@import 'informative-page.less';
@import 'server-error.less';
@import 'print.less';
@import 'spinner.less';
@import 'grid.less';
@import 'product-stock-status.less';
@import 'check-giftcard-balance-page.less';
@import 'account.less';
@import 'cms-usp.less';
@import 'cms-image-text-component.less';
@import 'orderconfirmation.less';
@import 'cms-article-module.less';
@import 'cms-theme-module.less';
@import 'tab.less';
@import 'table.less';
@import 'empty-state.less';
@import 'navigation-toggler.less';
@import 'expandable.less';
@import 'article-category.less';
@import 'cms-podcast-component.less';
@import 'productguide.less';
@import 'cms-top-module.less';
@import 'cms-services-module.less';
@import 'cms-article-frontpage-module.less';
@import 'salesforcechatcomponent.less';
@import 'list-action-buttons.less';
@import 'cms-facts-box.less';
@import 'cms-downloadable-file.less';
@import 'placeholder-text.less';
@import 'alignments.less';
@import 'outdatedBrowser.less';
@import 'sanity.less';

body {
    line-height: 100%;
    font-family: 'Roboto', sans-serif;
    color: @color-swamp;
    font-size: 16px;

    @media @to-large {
        font-size: 14px;
    }

    background-color: @site-background;
}

a,
button.link {
    cursor: pointer;
    border-bottom: 1px solid @color-blue-stone;
    text-decoration: none;
    color: @color-blue-stone;

    &.disabled {
        color: @btn-link-disabled-color;
        cursor: not-allowed;

        &:focus,
        &:active,
        &:hover {
            text-decoration: none;
        }
    }

    &:active,
    &:hover,
    &:focus {
        text-decoration: none;
    }

    &:active,
    &:hover {
        border-bottom-width: 2px;
    }
}

button.link {
    // workaround for border changing size
    --button-border-bottom: 1px;
    padding: 0 0 var(--button-border-bottom) 0;
    background: inherit;
    appearance: none;
    display: inline;

    &:active,
    &:hover {
        --button-border-bottom: 0px;
    }
}

a.button {
    .link--no-border;
}

.text-primary {
    color: @color-swamp;
}

.text-secondary {
    color: @color-oxford;
}

.logo-top {
    display: none;
}

.list-unstyled {
    margin: 0;
    padding: 0;
    list-style: none;
}

.link--no-border {
    border-bottom: none;

    &:focus,
    &:active,
    &:hover {
        border-bottom: none;
    }
}

.link-external {
    display: inline-flex;
    align-items: baseline;
    height: 17px;

    span {
        margin-right: 5px;
    }
}

.heading-separator {
    display: flex;
    align-items: center;
    .section-spacing;

    h2 {
        margin: 0;
        padding: 0 32px;
        text-align: center;

        @media @to-xsmall {
            padding: 0 16px;
        }
    }
}

.heading-separator--line {
    background-color: @color-nevada;
    height: 1px;
    flex: 2 1 auto;
}

@media print {
    a[href]:after {
        content: none;
    }
}

*,
::before,
::after {
    box-sizing: border-box;
}

button,
[role="button"] {
    cursor: pointer;
}

img {
    vertical-align: middle;
}