.page-articleCategory #page {
    margin: 0;
}

.search-category-page {
    .page-heading {
        margin: 0;
        padding: 80px;
        background-color: @color-apple-green;
        color: @color-swamp;

        h1 {
            font-family: @font-family-serif;
        }

        @media @to-large {
            margin-bottom: 16px;
        }
    }
}