@import '../../../less/variables.less';

.text-with-asset-card {
  background-color: @color-old-lace;
  display: flex;
  flex-direction: column;

  gap: 24px;
  padding: 40px 24px;

  h2 {
    font-size: var(--font-size-h3);
  }

  picture {
    align-self: center;
  }

  img {
    width: 172px;
  }

  @media @desktop {
    flex-direction: row;

    picture {
      align-self: start;
    }
  }
}

.podcast {
  iframe {
    height: 100px;
    width: 100%;
    border: none;

    @media(@desktop) {
      height: 200px;
    }
  }
}

.table {
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  max-width: 100%;

  // Force enough specificity to override the margin for all block content inside sanity articles
  :global(article.sanity>section) & {
    margin-top: 0;
  }

  table {
    background-color: @color-white;

    tr:nth-child(even) {
      background-color: @color-narvik;
    }

    th,
    td {
      padding: 8px;
      font-size: var(--font-size-small);
      border: 1px solid @color-grey-03;
    }

    th {
      font-weight: bold;
    }
  }
}

.fileHyperLink {
  --icon-size: 16px;
  margin-right: var(--icon-size);

  &:after {
    content: "";
    position: absolute;
    background: url(../../../images/svg/icon-download.svg) no-repeat center;
    width: var(--icon-size);
    height: var(--icon-size);
  }
}

.fileComponent {
  container-type: inline-size;
  display: grid;

  a {
    font-family: @font-family-sans-serif;
    background-color: @color-mint-green;
    color: @color-oxford;
    padding: 16px;
    display: grid;
    column-gap: 16px;
    border: none;
    justify-items: center;
    align-content: stretch;
    text-align: center;
    font-size: 16px;
    gap: 8px;

    & .category {
      font-size: 14px;
      font-weight: 700;
    }

    &:hover {
      h3 {
        text-decoration: underline;
      }
    }

    @media(@desktop) {
      padding: 24px;
    }

    h3 {
      font-family: @font-family-serif;
      font-size: 20px;
      font-weight: bold;
    }

    .fileInfo {
      display: flex;
      align-self: end;
    }

    @container (width > 580px) {
      grid-template-columns: minmax(0, 1fr) max-content;
      justify-items: unset;
      text-align: unset;

      >* {
        grid-column: 1;
      }

      .fileInfo {
        grid-row: 1;
        grid-column: 2;
        align-self: start;
      }
    }

    &:hover {
      border-bottom: none;
    }
  }
}

.grid {
  display: flex;
  flex-direction: column;
  gap: var(--row-gap);

  @container sanity-article (min-width: 1px) {
    // This monstrosity makes the grid take up the entire `article.sanity` width, not just the `article.sanity section` width.
    // It calculates a margin that is either negative or 0, by taking half the difference between the width of the content and the width of the sanity article container.
    // However, if --is-blog-layout is set it will not do this, since the blog cannot have a grid that is wider than the content
    --margin-inline: ~"calc(min(0px, var(--is-blog-layout, var(--max-content-width)) - var(--is-blog-layout, 100cqw)) / 2 )";
  }
}

.popup {
  position: relative;
  border-bottom: 1px dashed @color-blue-stone;
  cursor: help;

  h2 {
    font-family: @font-family-serif;
  }

  .tooltip {
    position: absolute;
    cursor: default;
    background-color: #fff;
    color: #2c2c2c;
    font-style: normal;
    font-size: var(--font-size-smaller);
    width: 390px;
    max-width: calc(100vw - 2 * var(--side-paddings));
    bottom: calc(100% + 8px);
    left: 50%;
    transform: translateX(-50%);
    padding: 16px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .2);
    z-index: 2;

    p {
      margin-bottom: 1em;
    }

    h2::first-letter {
      text-transform: uppercase;
    }

    a {
      font-size: var(--font-size-small);
    }

    &::before {
      bottom: 0;
      left: calc(50% - var(--popover-translation-x, 0px));
      transform: translate3d(-50%, 50%, 0) rotate(45deg);
      position: absolute;
      width: 16px;
      height: 16px;
      background-color: #fff;
      box-shadow: 7px 7px 7px 0 rgba(0, 0, 0, .1);
      content: "";
    }
  }
}