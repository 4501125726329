// dette er menyknappen som vises på mindre skjermer - dvs hamburger som toggler over i X

.hamburger-menu {

    button {
        position: relative;
        background-color: transparent;
        border: 0;
        width: 28px;
        height: 40px;
        padding: 0;
        display: grid;
        place-content: center;

        &:focus {
            text-decoration: none;
        }
    }
}

.hamburger-menu__icon {
    position: relative;
    display: block;
    width: 16px;
    height: 12px;
}

.hamburger-menu__icon span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: @color-iron;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;

    &:nth-child(1) {
        top: 0;

        [aria-expanded=true] & {
            width: 0;
            left: 50%;
            top: 5px;
        }
    }

    &:nth-child(2) {
        top: 5px;

        [aria-expanded=true] & {
            transform: rotate(45deg);
        }
    }

    &:nth-child(3) {
        top: 5px;

        [aria-expanded=true] & {
            transform: rotate(-45deg);
        }
    }

    &:nth-child(4) {
        top: 10px;

        [aria-expanded=true] & {
            width: 0;
            left: 50%;
            top: 5px;
        }
    }
}