@import '../../less/variables.less';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  background-color: @color-apple-green;
  font-size: 14px;
  padding: 0 16px;

  button {
    all: unset;
    background: url("../../images/svg/icon-close.svg") no-repeat right center;
    height: 20px;
    width: 20px;
  }

  @media @from-medium {
    display: none;
  }
}