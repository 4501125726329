.site__logo-and-nav-toggler {
    height: var(--header-navbar-height);
    display: grid;
    grid-template:
        'logo search menu' auto /
        min-content 1fr min-content;
    align-items: center;
    justify-items: center;
    padding: 0 16px;
    width: 100%;
    position: relative;

    @media @from-medium {
        padding: 0;
    }

    @media @from-xlarge {
        grid-template:
            'logo search nav menu' auto /
            min-content minmax(320px, auto) minmax(320px, auto) min-content;
    }

    // vmp-logo
    .site__go-home {
        grid-area: logo;
        display: block;
        width: 36px;
        height: 36px;
        margin: 3px 0 0 0;

        &:before {
            display: block;
            text-align: left;
            margin: 0;
            content: '';
            width: 36px;
            height: 36px;
            background: url('../images/crest.svg') 0 0 no-repeat;
            background-size: 36px 36px;
        }

        @media @from-medium {
            width: 212px;
            height: 16px;
            margin: 0;

            &:before {
                width: 212px;
                height: 16px;
                background: url('../images/header/header-logo.svg') 0 0 no-repeat;
                background-size: 212px 16px;
            }
        }

        @media @from-large {
            width: 261px;
            height: 20px;

            &:before {
                width: 261px;
                height: 20px;
                background-size: auto;
            }
        }
    }

    @media @to-small {
        .site__search-container {
            grid-area: 1/1/-1/-1; // Cover the entire width
            position: relative;
            z-index: 1;
        }
    }

    // butikker, les og lær osv for desktop
    .primary-navigation-desktop {
        grid-area: nav;
        justify-self: start;
        display: none;

        @media @from-xlarge {
            display: unset;
        }

        .primary-navigation-items-desktop {
            .primary-navigation__top-nav-items {
                display: flex;
                gap: 32px;

                // butikker, les og lær osv
                >li {
                    white-space: nowrap;
                    text-align: center;
                    position: relative;

                    &>a {
                        color: @color-iron;
                        font-weight: bold;
                        font-size: 14px;

                        &:hover {
                            color: @color-white;
                        }

                        &.navigation-toggler {
                            .navigation-toggler-white;
                        }
                    }


                    .primary-navigation__top-nav-item-children {
                        left: 0;
                        position: absolute;
                        width: 200px;
                        z-index: 20;
                        top: 46px;

                        a:hover {
                            background-color: @color-oxford;
                        }

                    }
                }
            }
        }
    }

    .secondary-navigation {
        grid-area: menu;
    }


    .hamburger-menu {
        @media @from-xlarge {
            display: none;
        }
    }
}

.header-mobile-menu {
    position: absolute;
    z-index: 20;
    background-color: @color-swamp;
    padding: 16px;
    overflow-y: scroll;
    max-height: calc(100vh - 100%);

    top: 100%;
    left: 0;
    right: calc(-1 * var(--section-padding));
    width: 100%;

    @media @to-xxsmall {
        height: calc(100vh - 100%);
    }

    @media @from-medium {
        left: unset;
        width: 375px
    }

    display: none;

    [aria-expanded=true]+& {
        display: block;
    }

    // butikker, les og lær osv for mindre enn desktop
    .primary-navigation__top-nav-items>li {
        background-color: @color-oxford;
        margin-bottom: 4px;
        border-radius: @border-radius;

        &:last-of-type {
            margin-bottom: 0;
        }

        &.active,
        &:hover {
            background-color: @color-nevada;
        }

        &>a {
            color: @color-white;
            padding: 16px;
            width: 100%;
            display: block;
            position: relative;
            font-weight: bold;

            &.navigation-toggler {
                .navigation-toggler-white;

                &:after {
                    position: absolute;
                    right: 16px;
                    top: 21px;
                }
            }
        }

        .primary-navigation__top-nav-item-children {
            padding: 12px 0 8px 0;
            gap: 4px;

            a {
                border: 1px solid @color-oxford;
                border-radius: @border-radius;
            }
        }
    }

    .primary-navigation__logout {
        background-color: @color-swamp;
        border: 1px solid @color-oxford;
        margin-top: 24px;
        display: block;
        border-radius: @border-radius;

        a {
            text-align: center;
            color: @color-white;
            padding: 16px;
            width: 100%;
            display: block;
            position: relative;
            font-weight: bold;

            &.btn-spinner {
                color: transparent;
                pointer-events: none;
                cursor: default;
            }

            &:hover {
                background-color: @color-oxford;
            }
        }
    }
}

// active/hover-state for linkene
.secondary-navigation__link-active() {
    &:after {
        transform: scaleX(1);
        text-decoration: none;
    }

    .secondary-navigation__link-name {
        color: @color-mint-green;
    }

    &.min-side__link {
        .secondary-navigation__link-icon {
            &:before {
                .icon-my-profile-header--active;
            }
        }
    }

    &.favourites__link {
        .secondary-navigation__link-icon {
            &:before {
                .icon-favorite-header--active;
            }
        }
    }

    &.cart__link {
        .secondary-navigation__link-icon {
            &:before {
                .icon-cart-header--active;
            }
        }
    }
}

.secondary-navigation {
    display: flex;
    align-items: center;
    gap: 24px;

    @media @to-medium {
        gap: 12px;
    }

    .secondary-navigation__item {
        @media @from-large {
            width: 70px;
            .secondary-navigation__item-fullsize;
            text-align: center;
            line-height: 15px;
        }
    }

    @media @to-large {
        .secondary-navigation__link-name {
            display: none;
        }
    }

    // min side og mine lister i mobilmeny
    .secondary-navigation-items-mobile {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        gap: 16px;
        margin-bottom: 16px;

        .secondary-navigation__link {

            &.min-side__link,
            &.favourites__link {
                display: inline-flex;
                padding: 24px;
                flex-direction: column;
                align-items: center;
                width: 100%;
            }
        }

        .secondary-navigation__item {
            width: 100%;
            .secondary-navigation__item-fullsize;
            background-color: @color-oxford;
            border-radius: @border-radius;
            margin: 0;
            flex: 1 1 auto;
        }
    }
}

/* Menypunktene Min side, Mine lister og Handlekurv */

.secondary-navigation__item-fullsize() {
    text-align: center;
    line-height: 15px;

    .secondary-navigation__link {
        border-top: 2px solid transparent;
    }

    .secondary-navigation__link-icon {
        padding-bottom: 4px;
    }

    .secondary-navigation__link-name {
        color: @color-iron;
        font-size: 14px;
        display: block;
        overflow: hidden;
        max-width: 75px;
        white-space: nowrap;
        margin: 0;

        &:before {
            content: none;
        }
    }
}

.secondary-navigation__item {
    .secondary-navigation__link {
        padding: 0;

        &.min-side__link,
        &.favourites__link {
            @media @to-medium {
                display: none;
            }
        }

        &:hover,
        &:active,
        &:focus {
            .secondary-navigation__link-active;
        }

        @media @from-large {
            border-top: 2px solid transparent;
            display: inline-block;

            &:after {
                border-bottom: 2px solid @color-mint-green;
                transform: scaleX(0);
                transition: transform 150ms ease-in-out;
                display: block;
                content: '';
            }

            &:hover,
            &:active,
            &:focus {
                &:after {
                    transform: scaleX(1);
                    text-decoration: none;
                }
            }
        }

        .secondary-navigation__link-icon {
            display: inline-block;
            text-align: center;

            @media @from-large {
                padding-bottom: 4px;
            }

            &:before {
                content: '';
                height: 28px;
                width: 28px;
                display: block;
                padding: 12px 8px 8px;
                color: @color-iron;

                @media @from-medium {
                    padding: 0;
                }
            }
        }

        &.min-side__link {
            .secondary-navigation__link-icon {
                &:before {
                    .icon-my-profile-header;
                }
            }
        }

        &.favourites__link {
            .secondary-navigation__link-icon {
                &:before {
                    .icon-favorite-header;
                    .generic-icon(28px);
                    background-color: @color-iron;
                }
            }
        }

        &.cart__link {
            .secondary-navigation__link-icon {
                &:before {
                    .icon-cart-header;
                }
            }

            .cart__icon {
                position: relative;

                .cart__count {
                    background-color: @color-berry-red;
                    color: white;
                    font-size: 13px;
                    font-weight: bold;
                    border-radius: 11px;
                    line-height: 100%;
                    min-width: 22px;
                    height: 22px;
                    position: absolute;
                    padding: 5px;
                    left: auto;
                    right: -12px;
                    top: -5px;

                    &[data-count='0'] {
                        display: none;
                    }
                }
            }
        }
    }

    /** Cart Active state when in cart page **/
    .cart__link {
        .page-cartPage & {
            .secondary-navigation__link-active;
        }
    }

    /** Active state when in page **/
    .min-side__link {

        .page-login &,
        .page-account & {
            .secondary-navigation__link-active;
        }
    }

    /** Active state when in page **/
    .favourites__link {
        .page-wishlistPage & {
            .secondary-navigation__link-active;
        }
    }
}

body {
    --header-navbar-height: 64px;
    --header-category-bar-height: 56px;
    --header-height: calc(var(--header-navbar-height) + var(--header-category-bar-height));
    --header-max-height: calc(var(--header-navbar-height) + var(--header-category-bar-height));

    @media @from-large {
        --header-navbar-height: 76px;
    }

    &:has(.header-category-bar.scroll-hidden) {
        --header-height: var(--header-navbar-height);
    }
}

.primary-navigation__top-nav-item-children {
    background-color: @color-swamp;
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;

    a {
        display: block;
        text-align: left;
        padding: 16px;
        font-size: 16px;
        font-weight: bold;
        color: @color-iron;
        line-height: 100%;
        background-color: @color-swamp;

        &:hover {
            color: @color-white;
        }
    }

    [aria-expanded]:not([aria-expanded=true])+& {
        display: none;
    }
}