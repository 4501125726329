.store-locator {
    width: 100%;
    text-align: center;

    h3 {
        margin-bottom: 10px;
    }

    @media @to-medium {
        p {
            margin: 16px;
        }
    }

    &__container {

        &.dialog-main {
            min-height: 450px;
        }

        .store-finder-info {
            margin: 0;
            padding-top: 8px;

            @media @from-medium {
                padding-top: 0;

                p {
                    margin: 8px auto;
                }
            }
        }
    }

    .store-list {
        list-style: none;
        padding: 0;

        @media @to-medium {
            margin: 0 16px;
        }
    }

    .btn--white {
        border: 0;
        padding: 16px;
        width: 100%;

        &:hover {
            background-color: @color-haze;
        }

        display: flex;
        align-items: center;
        justify-content: space-between;

        .expand-button {
            margin-right: 16px;
        }

        .store-info__name {
            .primary-label;
            width: calc(~'100% - 90px');
            text-align: left;
        }

        .store-info__distance,
        .store-info__stocklevel {
            .secondary-label;
            width: 90px;
            text-align: right;
            white-space: nowrap;
        }

        .store-info__right-arrow {
            .icon-chevron-right;
            padding: 16px;
        }
    }

    .store-locator__message {
        padding-top: 10px;
    }

    .error-message {
        margin-bottom: 20px;
    }

    .search-form {
        color: @color-oxford;
        font-size: 16px;
        color: @color-iron;
        border: none;
        -webkit-box-shadow: none;
        box-shadow: none;

        input {
            transition: width .3s ease-in-out;
            text-align: center;
            width: 250px;
        }

        input:focus {
            -webkit-box-shadow: none;
            box-shadow: none;

            @media @from-large {
                width: 300px;
            }
        }
    }
}

.page-storefinderPage,
.page-storeinformation {
    .store-details {
        @media @from-medium {
            padding: 48px;
        }

        div>p {
            margin: 0;
            padding: 16px;
        }
    }
}

.store-finder-wrapper {
    position: relative;
    min-height: 300px;
    margin: 0 auto;
    max-width: @screen-xs-max;

    .store-finder-info {
        text-align: center;

        .icon-external-link {
            display: inline-block;
            margin-left: 8px;
        }
    }

    .store-info__actions {
        @media @to-medium {
            margin: 0 16px;
        }

        .btn-secondary {
            width: 100%;
        }
    }
}

.store-info__actions {
    display: flex;
    text-align: center;

    @media @to-medium {
        display: block;
        text-align: left;
    }

    p {
        margin: auto;
        width: 100%;
    }

    .btn-secondary {
        margin: 8px 0;

        a {
            color: @color-swamp;
            border-bottom: none;
        }
    }

    .btn-secondary:first-child {
        margin-right: 8px;
    }
}

.primary-label {
    .text-primary;
    font-weight: bold;
    font-size: 14px;

    @media @from-medium {
        font-size: 16px;
    }
}

.secondary-label {
    .text-secondary;
    font-weight: bold;
    font-size: 14px;
}

.store-finder-search {
    margin: 24px auto;
    display: flex;
    justify-content: center;
    transition: width 0.3s ease-in-out;

    .search-form {
        padding: 0;
        display: flex;
        min-height: 44px;
        align-items: center;
        position: relative;

        .search-button {
            position: absolute;
            background: transparent;
            padding: 10px;
            color: @color-nevada;
            pointer-events: none;
        }

        &:focus-within .search-button {
            pointer-events: unset;
        }

        input {
            border: 2px solid @color-oxford;
            border-radius: 28px;
            font-weight: normal;
            height: auto;
            background-color: @color-white;
            padding: 14px;
            padding-left: 44px;
            font-size: 16px;
            color: @color-swamp;

            &:focus {
                background-color: @color-white;
                box-shadow: none;
            }

            &:hover,
            &:focus,
            &:active {
                border: 2px solid @color-mint-green;
            }
        }

        .autocomplete {
            display: none;
        }

        &:focus-within .autocomplete {
            display: block;

            margin: 8px 0 0 0;
            padding: 0;
            width: 100%;
            left: 0;
            right: 0;
            top: 100%;
            position: absolute;
            background-color: @color-white;
            border-radius: 4px;
            z-index: 1;
            box-shadow: 0 0 8px #0004;

            @media @to-small {
                // Make the box shadow bigger on small devices,
                // where the autocomplete takes up almost the entire width of the screen
                box-shadow: 0 0 16px 10px #0004;
            }

            li {
                padding: 16px;
                line-height: 120%;
                color: @color-blue-stone;
                list-style: none;
                font-weight: bold;
                cursor: pointer;

                &:hover,
                &:active {
                    background-color: @color-narvik;
                }
            }

            .suggestion-active {
                background-color: @color-narvik;
            }
        }
    }

}


.store-facet {
    margin-bottom: 16px;

    .store-finder-search {
        margin: 0 var(--facets-horizontal-margin, 16px);
        align-items: stretch;
        gap: 8px;

        .search-form {
            flex: 1 1 auto;

            input {
                background: @color-grey-01-5;
                border-color: transparent;
                border-radius: 24px;
            }
        }

        .locate-button {
            --icon-size: 16px;
            width: 24px;
            display: grid;
            place-content: center;
        }
    }

    .info-message {
        margin: 16px 16px 0 16px;
    }
}