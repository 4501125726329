.store-info {
    margin-bottom: 8px;

    .spinner {
        margin: 0 auto 10px;
    }
}

.store-finder-info {
    margin-bottom: 24px;

    .info-message {
        margin-top: 24px;
    }

    p {
        margin-bottom: 8px;

        a {
            margin-left: 4px;
        }
    }
}

.store-details {
    padding: 16px;
    background-color: @color-white;
    margin: 0 0 8px 0;
    width: 100%;
    border-radius: 0 0 4px 4px;
    text-align: left;
    color: @color-oxford;
    font-size: 14px;

    @media @to-medium {
        padding: 0;
        margin: 0;
    }

    .link-to-map {
        white-space: nowrap;

        .icon-external-link {
            display: inline-block;
            margin-left: 8px;
        }
    }

    .store-info__openings {
        margin: 16px 0;
        display: grid;
        width: 100%;

        .head-row,
        .day-row {
            display: flex;
            flex-wrap: wrap;

            >div {
                padding: 8px;
                color: @color-oxford;

                @media @to-medium {
                    &:nth-child(1) {
                        width: 60%;
                    }

                    &:nth-child(2) {
                        width: 40%;
                        text-align: right;
                    }

                    &:nth-child(3) {
                        width: 100%;
                    }
                }

                @media @from-medium {
                    &:nth-child(1) {
                        width: 30%;
                    }

                    &:nth-child(2) {
                        width: 20%;
                        text-align: right;
                    }

                    &:nth-child(3) {
                        width: 50%;
                    }
                }
            }
        }

        .head-row {
            >div {
                font-weight: bold;
            }
        }

        .day-row {
            &:nth-child(even) {
                background-color: @color-grey-01-5;
            }
        }

        .traffic-header-label {
            @media @to-medium {
                display: none;
            }
        }

        .store-info__openings--date {
            display: flex;
        }
    }

    .store-info__traffic {
        @media @to-medium {
            margin: 0 0 16px 0;
        }

        .day-row {
            flex-wrap: wrap;
            padding: 16px;

            @media @from-medium {
                &:nth-child(odd) {
                    background-color: @color-grey-01-5;
                }
            }

            @media @to-medium {
                &:nth-child(even) {
                    background-color: @color-grey-01-5;
                }
            }

            >div {
                color: @color-oxford;
            }
        }

        .store-info__openings--date {
            display: flex;
            font-weight: bold;
            justify-content: space-between;
        }

        .store-info__openings--traffic {
            margin-top: 8px;

            .timeslots {
                display: flex;
                flex-direction: row;
                grid-column-gap: 4px;

                .timeslot {
                    line-height: 130%;
                    flex: 1;

                    .color {
                        height: 8px;
                        border-radius: 10px;
                        width: 100%;

                        &.red {
                            background-color: @color-solid-red;
                        }

                        &.yellow {
                            background-color: @color-supernova;
                        }

                        &.green {
                            background-color: @color-limeade;
                        }
                    }

                    .interval {
                        color: @color-oxford;
                        text-align: center;
                        width: 100%;
                        padding-top: 0;
                    }

                    .interval-desc {
                        font-size: 12px;
                        padding-top: 4px;
                    }
                }
            }
        }
    }
}