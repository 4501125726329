.cms-podcast {
    display: flex;
    flex-direction: row;
    background-color: @color-white;
    .section-spacing;
    &.noImage {
        background-color: @color-narvik;
        display: flex;
        align-items: center;
    }

    @media @to-small {
        flex-direction: column;
    }
    
    .podcast-image {
        width: 50%;

        @media @to-small {
            width: 100%;
        }
        .cmsimage {
            overflow: hidden;
            display: block;
            position: relative;
            height: 100%;
            padding-top: 70%;
        }
        img {
            margin: 0;
            position: absolute;
            min-width: 1000%;
            min-height: 1000%;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%) scale(0.1);
        }
        &.noImage {
            background-image: url(../images/svg/VMP_podkast_merket_sort_RGB.svg);
            background-repeat: no-repeat;
            background-position: center;
            height: 150px;
            width: 30%;
            @media @to-small {
                width: 100%;
                margin: 16px 0;
            }
            @media @to-large {
                height: 120px;
            }
        }
    }
    
    .podcast-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 50%;
        padding: 16px;
        background-color: @color-white;

        @media @to-small {
            width: 100%;
        }

        @media @from-large {
            padding: 24px;
            width: 70%;
        }

        &.noImage {
            width: 70%;
            @media @to-small {
                width: 100%;
            }
        }

        .podcast-content__content {
            margin: 0;
        }
        .podcast-content__title {
            font-family: @font-family-serif;
        }

        .podcast-content__date {
            .product__category-name;
            margin-top: 0;
        }

        .podcast-content__links {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 80%;

            @media @to-xsmall {
                flex-direction: column;
                width: auto;
                a:first-child {
                    margin-bottom: 8px;
                }
            }
        }
    }
}