
.usp {
    padding: 0;

    .usp-bar {
        display: flex;
        justify-content: space-between;
        gap: 40px;

        @media (max-width: @screen-md) {
            gap: 16px;
        }

        @media (max-width: @screen-sm) {
            flex-direction: column;
        }
    }

    .usp-item {
        padding: 24px 32px;
        background-color: @color-white;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        flex: 1 1 0;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: @screen-sm) {
            font-size: 16px;
            padding: 16px 24px;
        }
    }

    .usp-link {
        .text-primary;
        border-bottom: 1px solid transparent;

        &:hover {
            border-bottom: 1px solid currentColor;
        }
    }

  }
