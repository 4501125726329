article.sanity {
  --side-paddings: 16px;
  --row-gap: 24px;
  --max-content-width: 840px;
  --max-article-width: 1288px;
  container: sanity-article / inline-size;
  width: 100%;
  max-width: calc(var(--max-article-width) + 2 * var(--side-paddings) - env(safe-area-inset-left) - env(safe-area-inset-right));
  display: flex;
  flex-direction: column;
  margin: 16px auto;
  padding: 0;
  padding-left: calc(var(--side-paddings) + env(safe-area-inset-left));
  padding-right: calc(var(--side-paddings) + env(safe-area-inset-right));

  row-gap: var(--row-gap);

  .category-page {
    --category-page-row-gap: 32px;
    display: grid;
    gap: var(--category-page-row-gap);
    // force the "gap" at the top to be the same as inside
    margin-top: calc(var(--category-page-row-gap) - var(--row-gap));

    @media @desktop {
      --category-page-row-gap: 80px;
    }

    >h2 {
      margin: 0;
    }
  }

  @media @desktop {
    --margin-inline: 40px;
    margin-block: 24px 64px;

    display: grid;
    grid-template-columns: 1fr minmax(0, var(--max-content-width)) 1fr;

    &:has(nav.toc:not(:empty)) {
      column-gap: 40px;
      grid-template-columns: min(33%, 400px) minmax(0, 1fr);
      --is-blog-layout: 1;
    }
  }

  &>* {
    grid-column: 1/-1;
  }

  // Breadcrumbs
  >nav.breadcrumbs {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      color: @color-oxford;
      font-size: var(--font-size-smaller);
      line-height: 1.2;

      li {
        text-wrap: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        flex: 1 1 0px;
        max-width: max-content;

        &:not(:first-child)::before {
          content: '/';
          margin-inline: 1ch;
        }
      }

      a {
        color: inherit;
      }
    }
  }

  // title and ingress
  >header {
    display: grid;
    gap: 24px;
    max-width: var(--max-content-width);
    margin: 0 auto;
    padding: 0;

    >h1 {
      font-family: @font-family-serif;
      font-weight: bold;
      color: @color-primary;
      margin: 0;
      text-wrap: balance;
      font-size: var(--font-size-h1);
      line-height: 44px;

      @media @desktop {
        line-height: 56px;
      }
    }

    >p {
      text-align: center;
      color: @color-oxford;
      margin: 0;
      line-height: 1.5;
      font-size: var(--font-size-ingress);
      font-family: @font-family-serif-ingress;

      &:empty {
        display: none;
      }
    }
  }

  .lined-heading {
    display: flex;
    gap: 16px;
    font-family: @font-family-serif;
    font-size: var(--font-size-h2);
    align-items: center;
    text-align: center;
    font-weight: bold;
    color: @color-swamp;
    margin-inline: 0;
    max-width: none;

    @media @desktop {
      font-size: 34px;
      line-height: 40px;
    }

    &:before,
    &:after {
      content: "";
      flex: 1;
      border-bottom: 1px solid @color-nevada;
    }

  }

  // banner-image
  >figure {
    margin: 0;
  }

  // tags
  >aside.tags {
    grid-column: 2;
    border-bottom: 1px solid @color-iron;
    padding-bottom: 24px;

    @media @desktop {
      margin-inline: 40px;
      padding-bottom: 32px;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 8px;

      li {
        display: block;
        background: white;
        border-radius: 4px;
        padding: 4px 16px;
        color: @color-oxford;
        font-size: var(--font-size-small);
        line-height: 1.4;
      }
    }
  }

  // Table of contents
  >nav.toc {
    grid-column: 1;
    margin-top: calc(0px - var(--row-gap)); // This cancels out the gap in the flex layout on mobile, so it appears closer to the tags above it

    @media @desktop {
      margin-top: 0;

      ul {
        position: sticky;
        top: calc(16px + var(--header-height));
        transition: top .2s ease-out;
        max-height: calc(100vh - 16px - var(--header-height));
        overflow-y: auto;

        // only highlight the current entry on desktop
        li:nth-child(1 of .active) {
          font-weight: bold;

          a::before {
            font-weight: normal;
            content: '';
            .generic-icon(10px);
            background-image: url(../images/svg/icon-chevron-right.svg);
            display: block;
            width: 24px;
          }
        }
      }
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
    }

    a {
      padding-block: 8px;
      display: flex;
      align-items: center;
      border-color: @color-iron;
      color: @color-swamp;

      @media @desktop {
        padding-inline: 16px;
      }

      &:hover {
        text-decoration: underline;
        border-width: 1px;
      }
    }

  }

  >section {
    --margin-paragraph: 24px;
    grid-column: 2;
    margin: 0;
    padding: 0;
    font-size: var(--font-size-p);
    line-height: 1.5;
    color: @color-swamp;

    &:empty {
      display: none;
    }

    >* {
      margin-inline: var(--margin-inline, 0);
      margin-block: 32px;
      clear: both;

      @media @desktop {
        margin-block: 64px;
      }

      &:first-child {
        // make sure we don't have extra space at the top of the section
        margin-top: 0;
      }

      &:last-child {
        // make sure we don't have extra space at the bottom of the section
        margin-bottom: 0;
      }
    }

    p,
    ul,
    ol {
      margin-block: 0 var(--margin-paragraph);
      line-height: inherit;
      font-size: inherit;
      color: inherit;
      clear: none;
    }

    ul,
    ol {
      line-height: 1.7;
    }

    >h2,
    >h3,
    >h4,
    >h5 {
      color: inherit;
      margin-block: var(--margin-paragraph) 8px;
      clear: none;
    }

    >h2 {
      font-size: var(--font-size-h2);
      line-height: 32px;
      scroll-margin-top: var(--header-height, 0);
      margin-top: 32px;
      clear: both;

      @media @desktop {
        line-height: 40px;
      }
    }

    >h3 {
      font-size: var(--font-size-h3);
      line-height: 28px;

      @media @desktop {
        line-height: 32px;
      }
    }

    >h4 {
      font-size: var(--font-size-h4);
      line-height: 1.4;
    }

    blockquote {
      --padding: 24px;
      font-family: @font-family-serif-ingress;
      font-size: var(--font-size-h3);
      text-align: center;

      padding-block: var(--padding);

      @media @desktop {
        --padding: 40px;
        margin-block: 40px;
      }

      &::before,
      &::after {
        content: '';
        height: 22px;
        display: block;
        background: url('../images/svg/blockquote-bg.svg') no-repeat top center;
        background-size: 760px;
        position: relative;
        top: calc(0px - var(--padding));
      }

      &::after {
        background-position: center bottom;
        top: var(--padding);
      }
    }

    .textbox-with-color,
    aside.fact-box,
    address.contact-card,
    div.recipe {
      margin-inline: 0;

      padding-block: var(--margin-inline, 24px);
      padding-inline: var(--margin-inline, 16px);
    }

    .textbox-with-color,
    aside.fact-box {
      >h2 {
        font-size: var(--font-size-h2);
      }
    }

    .textbox-with-color,
    aside.fact-box,
    address.contact-card {
      >h2 {
        margin: 0;
        line-height: 1.4;
        margin-block: 0 8px;
      }
    }

    .textbox-with-color {
      &--yellow {
        background-color: @color-cream-brulee;
      }

      &--grey {
        background-color: @color-grey-02;
      }
    }

    .video-embed iframe {
      aspect-ratio: 16 / 9;
      width: 100%;
      height: 100%;
      border: none;
    }


    div.recipe {
      background-color: @color-grey-02;
    }

    aside.fact-box {
      background: @color-cream-brulee;

      >p:last-child {
        margin-bottom: 0;
      }
    }

    .link-card {
      --img-width: 100px;
      display: grid;
      background: white;
      border: none;
      border-radius: 4px;
      grid-template: 'title img'
        / 1fr var(--img-width);
      column-gap: 24px;
      color: @color-swamp;
      align-items: center;
      min-height: calc(9 / 16 * var(--img-width));
      margin-block: var(--margin-paragraph);

      @media @desktop {
        --img-width: 150px;
      }

      &:hover {
        outline: 1px solid @color-iron;

        h2 {
          text-decoration: underline;
        }
      }

      h2 {
        grid-area: title;
        font-family: @font-family-serif-ingress;
        margin: 16px 0 16px 16px;
        font-size: var(--font-size-h4);
        line-height: 1.5;
        font-weight: normal;
        overflow: hidden;
        text-overflow: ellipsis;

        @media @desktop {
          margin-left: 24px;
        }

        span {
          display: block;
          font-size: var(--font-size-smaller);
          line-height: 1.25;
          font-family: @font-family-sans-serif;
          font-weight: bold;
          margin-bottom: 4px;
          color: @color-nevada;
        }
      }

      picture {
        grid-area: img;
        width: 100%;
        height: 100%;
        position: relative;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          position: absolute;
        }
      }
    }

    address.contact-card {
      font-style: normal;
      background: @color-mint-green;
      display: grid;
      grid-template-columns: max-content 1fr;
      place-items: center start;
      gap: 8px;

      h2 {
        font-size: var(--font-size-h4);
        grid-column: span 2;
      }

      p {
        margin: 0;
      }

      .icon {
        background-color: currentColor;
      }
    }

    hr {
      border: 1px solid @color-nevada;
      border-bottom: none;
      margin-inline: 0;
    }

    aside.disclaimer {
      color: @color-oxford;

      h2 {
        font-size: var(--font-size-h4);
      }
    }
  }

  figure {
    >picture>img {
      width: 100%;
      margin: 0;
    }

    >figcaption {
      padding-block: 8px;
      text-align: left;
      color: @color-nevada;
      font-size: var(--font-size-small);
    }
  }

  @media @from-xxsmall {
    .float {
      --float-margin: 16px;

      @media @desktop {
        --float-margin: 24px;
      }

      width: 33%;
      margin-block: 0 var(--float-margin);
      overflow-wrap: anywhere;

      &.right {
        float: right;
        margin-left: var(--float-margin);
      }

      &.left {
        float: left;
        margin-right: var(--float-margin);
      }
    }
  }

  section.pressroom {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    column-gap: 40px;
    row-gap: var(--row-gap);
    grid-column: 1 / -1;
    max-width: 1060px;
    justify-self: center;

    >* {
      margin: 0;
    }

    .presscontacts {
      background: @color-old-lace;

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        font-size: var(--font-size-smaller);

        +ul {
          margin-top: 1em;
        }
      }
    }

    >address,
    >div {
      padding: 24px 16px;
      align-content: start;

      @media @desktop {
        padding: 32px 40px;
      }

      h2 {
        font-family: @font-family-serif;
        font-size: var(--font-size-h3);
      }
    }
  }

  .end-of-article-grid {
    margin-block: 60px;

    h2 {
      margin-bottom: 40px;
    }
  }
}

#sanity-visual-editing {
  a {
    border-bottom: unset;
  }
}


.top-module {
  width: stretch;
  margin: 0 calc(50cqw - 50vw);
  background-color: @color-aqua-deep;
  background-image:
    linear-gradient(to bottom,
      @color-aqua-deep 32px,
      @color-narvik 32px 39px,
      @color-aqua-deep 39px 47px,
      @color-narvik 47px 49px,
      transparent 49px),
    linear-gradient(to top,
      @color-aqua-deep 32px,
      @color-narvik 32px 39px,
      @color-aqua-deep 39px 47px,
      @color-narvik 47px 49px,
      transparent 49px);
  background-repeat: no-repeat;
  padding-block: 97px;
  padding-inline: 32px;

  >div {
    max-width: 1007px;
    margin: 0 auto;
  }

  h1 {
    font-size: 30px;
    line-height: 36px;
    color: @color-apple-green;
    font-family: @font-family-serif;
    margin-top: 0;
    margin-bottom: 42px;

    @media @desktop {
      font-size: 46px;
      margin-bottom: 50px;
      line-height: 1.2;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 24px;

    li {
      flex: 1 1 200px;

      @media @desktop {
        flex-grow: 0;
      }

      display: grid;

      a {
        border: 1px solid @color-narvik;
        border-radius: 4px;
        height: 60px;
        padding: 0 24px;
        color: @color-narvik;
        text-align: center;
        font-size: 18px;
        line-height: 28px;
        display: grid;
        place-content: center;

        &:hover {
          text-decoration: underline;
          box-shadow: 0 0 0 1px @color-narvik inset;
        }
      }
    }
  }
}

.services {
  display: grid;
  column-gap: 40px;
  row-gap: var(--category-page-row-gap);
  grid-template-columns: minmax(0, 1fr);
  font-size: var(--font-size-h3);

  @media @desktop {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    align-items: stretch;
  }

  a {
    display: grid;
    gap: 16px;
    place-content: center;
    text-align: center;
    grid-template-columns: minmax(0, 1fr);
    background: @color-mint-green;
    color: @color-swamp;
    font-family: @font-family-serif;
    font-size: inherit;
    font-weight: bold;
    line-height: 1.2;
    overflow-wrap: break-word;
    padding: 32px 16px;
    border-bottom: none;

    &:hover {
      text-decoration: underline;
    }

    picture img {
      width: 70px;
    }

    @media @mobile {
      &:nth-child(2) {
        order: -1;
      }
    }
  }
}