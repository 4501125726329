@import 'variables.less';

/**
 * Svg-basert løsning for visning av ikoner
 */

.generic-icon(@size) {
    background-repeat: no-repeat;
    background-position: center;
    background-size: var(--icon-size, @size) var(--icon-size, @size);
    width: var(--icon-size, @size);
    height: var(--icon-size, @size);
    flex: 0 0 auto;

    @media print {
        background-size: var(--icon-size, @size) var(--icon-size, @size) !important;
    }
}

.mask-image {
    -webkit-mask-image: var(--mask-image);
    mask-image: var(--mask-image);
    mask-repeat: no-repeat;
    -webkit-mask-repeat: no-repeat;
    mask-position: center;
    -webkit-mask-position: center;
    flex: 0 0 auto;
}

.mask-image(@url) {
    .mask-image;
    --mask-image: @url;
}

.mask-size(@width, @height) {
    mask-size: @width, @height;
    -webkit-mask-size: @width, @height;
}

.icon-set-backgound(@imagePath) {
    // Set important to display icons on print
    background-image: @imagePath !important;
    print-color-adjust: exact;
}

.icon-cart-header {
    .generic-icon(28px);
    background-image: url(../images/header/cart-icon.svg);
}

.icon-cart-header--active {
    .generic-icon(28px);
    background-image: url(../images/header/cart-icon-active.svg);
}

.icon-cart {
    .generic-icon(20px);
    background-image: url(../images/svg/icon-cart.svg);
}

.icon-chevron-up {
    .generic-icon(10px);
    background-color: @color-nevada;

    &.white {
        background-color: @color-white;
    }

    .mask-image(url(../images/svg/icon-chevron-up.svg));
}

.icon-chevron-down {
    .generic-icon(10px);
    background-color: @color-nevada;

    &.white {
        background-color: @color-white;
    }

    .mask-image(url(../images/svg/icon-chevron-down.svg));
}

.icon-chevron-right {
    .generic-icon(10px);
    background-image: url(../images/svg/icon-chevron-right.svg);
}

.icon-chevron-left {
    .generic-icon(10px);
    background-image: url(../images/svg/icon-chevron-left.svg);
}

.icon-favorite-header {
    background-color: @color-swamp;
    .generic-icon(32px);
    .mask-image(url(../images/header/favorite-icon.svg));
}

.icon-favorite-header--active {
    .generic-icon(28px);
    background-image: url(../images/header/favorite-icon--active.svg);
}

.icon-favorite {
    .generic-icon(20px);
    background-image: url(../images/svg/icon-favorite.svg);

    &.selected {
        background-image: url(../images/svg/icon-favorite-selected.svg);
    }
}

.icon-add {
    .generic-icon(20px);
    background-image: url(../images/svg/icon-add.svg);
}

.icon-my-profile-header {
    .generic-icon(28px);
    background-image: url(../images/header/my-profile-icon.svg);
}

.icon-my-profile-header--active {
    .generic-icon(28px);
    background-image: url(../images/header/my-profile-icon--active.svg);
}

/* PROFILE ICONS */
.icon-privacy {
    .generic-icon(24px);
    background-image: url(../images/svg/profile/icon-privacy.svg);
}

.icon-my-store {
    .generic-icon(24px);
    background-image: url(../images/svg/profile/icon-my-store.svg);
}

.icon-my-notifications {
    .generic-icon(24px);
    background-image: url(../images/svg/profile/icon-notification.svg);
}

.icon-payment-info {
    .generic-icon(24px);
    background-image: url(../images/credit-card-logo.svg);
}

.icon-my-profile {
    .generic-icon(24px);
    background-image: url(../images/svg/profile/icon-my-profile.svg);
}

.icon-order-list {
    .generic-icon(32px);
    background-image: url(../images/svg/profile/icon-order-list.svg);
}


.icon-review {
    .generic-icon(18px);
    background-image: url(../images/svg/icon-review.svg)
}

.icon-review-list {
    .generic-icon(18px);
    background-image: url(../images/svg/icon-review-list.svg)
}

.icon-review-list-favorite {
    .generic-icon(18px);
    background-image: url(../images/svg/icon-review-list-favorite.svg)
}

.icon-review-favorite {
    .generic-icon(18px);
    background-image: url(../images/svg/icon-review-favorite.svg)
}


/* PROFILE ICONS end*/

.icon-my-list {
    .generic-icon(18px);
    background-image: url(../images/svg/icon-list.svg);

    &.selected {
        background-image: url(../images/svg/icon-list-selected.svg);
    }
}

.icon-alert-error {
    .generic-icon(20px);
    background-image: url(../images/svg/icon-alert-error.svg);
}

.icon-alert-info {
    .generic-icon(20px);

    &.deep-sea-green {
        background-color: @color-deep-sea-green;
    }

    background-color: @color-swamp;
    .mask-image(url(../images/svg/icon-alert-info.svg));
}

.icon-close-button {
    .generic-icon(32px);
    background-image: url('../images/svg/icon-close-button.svg');
}

.icon-close {
    .generic-icon(24px);
    background-image: url('../images/svg/icon-close.svg');
}

.icon-xmark {
    .generic-icon(10px);
    background-color: @color-swamp;

    &.white {
        background-color: @color-white;
    }

    .mask-image(url(../images/svg/icon-xmark.svg));
}

.icon-external-link {
    .generic-icon(10px);
    .mask-image(url(../images/svg/icon-external-link.svg));
    background-color: currentColor;
}

.icon-stock {
    .generic-icon(22px);
    flex-shrink: 0;

    &.icon-store {
        background-color: @color-nevada;
        .mask-image(url(../images/svg/stock/icon-store.svg));

        &.unavailable {
            background-color: @color-iron;
        }
    }

    &.icon-delivery {
        background-color: @color-nevada;
        .mask-image(url(../images/svg/stock/icon-delivery.svg));

        &.unavailable {
            background-color: @color-iron;
        }
    }
}

.icon-email {
    .generic-icon(18px);
    background-color: var(--icon-color, @color-deep-sea-green);
    .mask-image(url('../images/svg/icon-email.svg'));
}

.icon-phone {
    .generic-icon(18px);
    background-color: var(--icon-color, @color-deep-sea-green);
    .mask-image(url('../images/svg/icon-phone.svg'));
}

.icon-chat {
    .generic-icon(18px);
    background-color: var(--icon-color, @color-deep-sea-green);
    .mask-image(url('../images/svg/icon-chat.svg'));
}

.icon-delete {
    .generic-icon(17px);
    background-color: @color-nevada;
    .mask-image(url(../images/svg/icon-delete.svg));

    &.black {
        background-color: @color-swamp;
    }
}

.icon-edit {
    .generic-icon(17px);
    background-image: url(../images/svg/icon-pencil.svg);
}

.icon-comment {
    .generic-icon(17px);
    background-image: url(../images/svg/icon-comment.svg);
}

.icon-increase {
    height: var(--icon-size, 12px);
    width: var(--icon-size, 12px);
    margin: auto;
    background-color: var(--icon-color, @color-swamp);
    .mask-image(url(../images/svg/icon-increase.svg));
}

.icon-decrease {
    height: var(--icon-size, 12px);
    width: var(--icon-size, 12px);
    margin: auto;
    background-color: var(--icon-color, @color-swamp);
    .mask-image(url(../images/svg/icon-decrease.svg));
}

.icon-frontpage-store {
    .generic-icon(90px);
    background-image: url(../images/frontpage/frontpage-store.svg);
}

.icon-checkmark {
    .generic-icon(24px);
    background-image: url(../images/svg/icon-checkmark.svg);
}

.icon-stacking-bottles {
    .generic-icon(200px);
    background-image: url(../images/svg/stacking-bottles-illustration.svg);
}

.icon-mobile-checkout {
    .generic-icon(200px);

    @media @to-small {
        .generic-icon(116px);
    }

    background-image: url(../images/svg/mobileCheckout.svg);
}

.icon-global-search {
    .generic-icon(24px);
    background-color: currentColor;
    .mask-image(url(../images/svg/icon-global-search.svg));
}

.icon-location-pin {
    .generic-icon(15px);
    background-image: url('../images/svg/icon-location-pin.svg');
}

.icon-download {
    .generic-icon(24px);
    background-image: url(../images/svg/icon-download.svg);
}

.icon-share {
    .generic-icon(20px);
    background-image: url(../images/svg/icon-share.svg);
}

/* BINDERS */
.icon-biodynamic {
    .generic-icon(24px);
    background-image: url(../images/svg/binders/icon-biodynamic.svg);
}

.icon-eco {
    .generic-icon(24px);
    background-image: url(../images/svg/binders/icon-eco.svg);
}

.icon-environmentmarking {
    .generic-icon(24px);
    background-image: url(../images/svg/binders/icon-environmentmarking.svg);
}

.icon-environmentpackaging {
    .generic-icon(24px);
    background-image: url(../images/svg/binders/icon-environmentpackaging.svg);
}

.icon-fairtrade {
    .generic-icon(24px);
    background-image: url(../images/svg/binders/icon-fairtrade.svg);
}

.icon-gluten {
    .generic-icon(24px);
    background-image: url(../images/svg/binders/icon-gluten.svg);
}

.icon-kosher {
    .generic-icon(24px);
    background-image: url(../images/svg/binders/icon-kosher.svg);
}

.icon-naturalwine {
    .generic-icon(24px);
    background-image: url(../images/svg/binders/icon-naturalwine.svg);
}

.icon-orangewine {
    .generic-icon(24px);
    background-image: url(../images/svg/binders/icon-orangewine.svg);
}

.icon-vegan {
    .generic-icon(24px);
    background-image: url(../images/svg/binders/icon-vegan.svg);
}

.icon-sweetwine {
    .generic-icon(24px);
    background-image: url(../images/svg/binders/icon-sweetwine.svg);
}

/* BINDERS end */

.icon-grape {
    .generic-icon(26px);
    background-color: currentColor;
    .mask-image(url(../images/svg/icon-grape.svg));
}

.product-icon {
    .generic-icon(26px);

    &.mainCategory {
        &.rødvin {
            background-image: url(../images/svg/facets/mainCategory-rødvin.svg);
        }

        &.hvitvin {
            background-image: url(../images/svg/facets/mainCategory-hvitvin.svg);
        }

        &.brennevin {
            background-image: url(../images/svg/facets/mainCategory-brennevin.svg);
        }

        &.øl {
            background-image: url(../images/svg/facets/mainCategory-øl.svg);
        }

        &.musserende_vin {
            background-image: url(../images/svg/facets/mainCategory-musserende_vin.svg);
        }

        &.rosévin {
            background-image: url(../images/svg/facets/mainCategory-rosevin.svg);
        }

        &.sterkvin {
            background-image: url(../images/svg/facets/mainCategory-sterkvin.svg);
        }

        &.perlende_vin {
            background-image: url(../images/svg/facets/mainCategory-perlende_vin.svg);
        }

        &.sider {
            background-image: url(../images/svg/facets/mainCategory-sider.svg);
        }

        &.aromatisert_vin {
            background-image: url(../images/svg/facets/mainCategory-aromatisert_vin.svg);
        }

        &.alkoholfritt {
            background-image: url(../images/svg/facets/mainCategory-alkoholfritt.svg);
        }

        &.sake {
            background-image: url(../images/svg/facets/mainCategory-sake.svg);
        }

        &.gaveartikler_og_tilbehør {
            background-image: url(../images/svg/facets/mainCategory-gaveartikler_og_tilbehør.svg);
        }

        &.fruktvin {
            background-image: url(../images/svg/facets/mainCategory-fruktvin.svg);
        }

        &.mjød {
            background-image: url(../images/svg/facets/mainCategory-mjød.svg);
        }
    }

    &.styleCategory {
        &.stil001 {
            .icon-set-backgound(url(../images/style/stil001.png));
        }

        &.stil002 {
            .icon-set-backgound(url(../images/style/stil002.png));
        }

        &.stil003 {
            .icon-set-backgound(url(../images/style/stil003.png));
        }

        &.stil004 {
            .icon-set-backgound(url(../images/style/stil004.png));
        }

        &.stil005 {
            .icon-set-backgound(url(../images/style/stil005.png));
        }

        &.stil006 {
            .icon-set-backgound(url(../images/style/stil006.png));
        }

        &.stil007 {
            .icon-set-backgound(url(../images/style/stil007.png));
        }

        &.stil008 {
            .icon-set-backgound(url(../images/style/stil008.png));
        }

        &.stil009 {
            .icon-set-backgound(url(../images/style/stil009.png));
        }

        &.stil010 {
            .icon-set-backgound(url(../images/style/stil010.png));
        }
    }

    &.isGoodfor {
        &.A {
            .icon-set-backgound(url(../images/svg/facets/isGoodFor-A.svg));
        }

        &.B {
            .icon-set-backgound(url(../images/svg/facets/isGoodFor-B.svg));
        }

        &.C {
            .icon-set-backgound(url(../images/svg/facets/isGoodFor-C.svg));
        }

        &.D {
            .icon-set-backgound(url(../images/svg/facets/isGoodFor-D.svg));
        }

        &.E {
            .icon-set-backgound(url(../images/svg/facets/isGoodFor-E.svg));
        }

        &.F {
            .icon-set-backgound(url(../images/svg/facets/isGoodFor-F.svg));
        }

        &.G {
            .icon-set-backgound(url(../images/svg/facets/isGoodFor-G.svg));
        }

        &.H {
            .icon-set-backgound(url(../images/svg/facets/isGoodFor-H.svg));
        }

        &.L {
            .icon-set-backgound(url(../images/svg/facets/isGoodFor-L.svg));
        }

        &.N {
            .icon-set-backgound(url(../images/svg/facets/isGoodFor-N.svg));
        }

        &.Q {
            .icon-set-backgound(url(../images/svg/facets/isGoodFor-Q.svg));
        }

        &.R {
            .icon-set-backgound(url(../images/svg/facets/isGoodFor-R.svg));
        }
    }

    &.Lagringsgrad {

        &.drikkenaa,
        &.X {
            .icon-set-backgound(url(../images/svg/facets/Lagringsgrad-drikkenaa.svg));
        }

        &.drikkelagre,
        &.Y {
            .icon-set-backgound(url(../images/svg/facets/Lagringsgrad-drikkelagre.svg));
        }

        &.lagre,
        &.Z {
            .icon-set-backgound(url(../images/svg/facets/Lagringsgrad-lagre.svg));
        }
    }

    &.Emballasjetype {
        &.Glass {
            .icon-set-backgound(url(../images/svg/facets/Emballasjetype-Glass.svg));
        }

        &.Bag-in-box {
            .icon-set-backgound(url(../images/svg/facets/Emballasjetype-Bag-in-box.svg));
        }

        &.Emballasje-med-pant {
            .icon-set-backgound(url(../images/svg/facets/EmballasjeType-Emballasje-med-pant.svg));
        }

        &.Metall {
            .icon-set-backgound(url(../images/svg/facets/Emballasjetype-Metall.svg));
        }

        &.Plast {
            .icon-set-backgound(url(../images/svg/facets/Emballasjetype-Plast.svg));
        }

        &.Øvrig {
            .icon-set-backgound(url(../images/svg/facets/Emballasjetype-Øvrig.svg));
        }

        &.Pouch {
            .icon-set-backgound(url(../images/svg/facets/Emballasjetype-Pouch.svg));
        }
    }

    &.icon-pie-1 {
        .icon-set-backgound(url(../images/svg/facets/icon-pie-1.svg));
    }

    &.icon-pie-2 {
        .icon-set-backgound(url(../images/svg/facets/icon-pie-2.svg));
    }

    &.icon-pie-3 {
        .icon-set-backgound(url(../images/svg/facets/icon-pie-3.svg));
    }

    &.icon-pie-4 {
        .icon-set-backgound(url(../images/svg/facets/icon-pie-4.svg));
    }

    &.icon-pie-5 {
        .icon-set-backgound(url(../images/svg/facets/icon-pie-5.svg));
    }

    &.icon-pie-6 {
        .icon-set-backgound(url(../images/svg/facets/icon-pie-6.svg));
    }

    &.icon-pie-7 {
        .icon-set-backgound(url(../images/svg/facets/icon-pie-7.svg));
    }

    &.icon-pie-8 {
        .icon-set-backgound(url(../images/svg/facets/icon-pie-8.svg));
    }

    &.icon-pie-9 {
        .icon-set-backgound(url(../images/svg/facets/icon-pie-9.svg));
    }

    &.icon-pie-10 {
        .icon-set-backgound(url(../images/svg/facets/icon-pie-10.svg));
    }

    &.icon-pie-11 {
        .icon-set-backgound(url(../images/svg/facets/icon-pie-11.svg));
    }

    &.icon-pie-12 {
        .icon-set-backgound(url(../images/svg/facets/icon-pie-12.svg));
    }

    &.icon-raastoff {
        .icon-set-backgound(url(../images/svg/facets/icon-raastoff.svg));
    }

    &.style {
        .icon-set-backgound(url(../images/svg/facets/style.svg));
    }
}


details {
    &>summary>.icon-details {
        .generic-icon(24px);
        background-image: url(../images/svg/icon-plus-with-bg.svg);
        rotate: 0deg;
        transition: rotate .5s;
    }

    &[open]>summary>.icon-details {
        rotate: 45deg;

    }
}