.site__header {
    --placeholder-text-color-1: transparent;
    --placeholder-text-color-2: transparent;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 10;
    pointer-events: none;

    &>* {
        pointer-events: all;
    }

    a {
        .link--no-border;
    }

    .section__logo-and-nav-toggler {
        background-color: @color-swamp;
        z-index: 1;
        position: relative;
    }
}

.site__body {
    position: relative;
    min-height: 100vh;
}

.site__footer {
    background-color: @color-swamp;
}