@import url('../../../less/variables.less');

.toast-container {
  position: fixed;
  top: 8px;
  z-index: 16;
  left: calc(50% - 150px);
  width: 300px;

  :global(.info-message) {
    background-color: @color-apple-green;
    color: @state-info-text;
    margin-top: 8px;
    position: relative;
    overflow: hidden;

    &::after {
      content: '';
      height: 3px;
      width: 0%;
      background: currentColor;
      position: absolute;
      left: 0;
      bottom: 0;
      animation: shrink 3s linear;

      @keyframes shrink {
        from {
          width: 100%;
        }

        to {
          width: 0%;
        }
      }
    }
  }
}