.services-component__items {
    display: flex;
    justify-content: center;
    .section-spacing;

    .services-component {
        flex: 1 1 0;
        margin-right: 40px;
        &:last-child {
            margin-right: 0;
        }
    }

    .service-component-1 {
        background-color: @color-mint-green;
        .service-item-component__top {
            padding: 16px 64px;
        }
    }

    .service-item__title {
        font-size: 28px;
        line-height: 33px;
        font-weight: bold;
        .text-primary;
        font-family: @font-family-serif;
    }

    .link__description {
        font-size: 16px;
        line-height: 25px;
        padding-top: 16px;
        .text-primary;
    }

    .link__icon--wrapper {
        display: flex;
        justify-content: center;
        padding-bottom: 16px;
    }

    .service-item-component {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        &.background {
            display: grid;
            position: relative;
            grid-template-columns: repeat(12, 1fr);
            align-items: unset;
        }
    }

    .service-item-component__background {
        grid-column: 1 / span 11;
        grid-row: 1;
        margin-bottom: 10%;
        img {
            width: 100%;
            display: block;
        }
    }

    .service-item-component__top {
        background-color: @color-mint-green;
    }

    .service-item__link {
        border-bottom: none;
        display: block;
        text-align: center;
        &:hover {
            .service-item__title {
                border-bottom: 1px solid;
                display: inline;
            }
        }
    }

    .background {
        .service-item-component__top {
            grid-row: 1;
            grid-column-start: span 11;
            grid-column-end: -1;
            margin-top: 8.33%; //added to support older browsers
            margin-top: calc(100%/12);
            z-index: 1;
            padding: 0 16px;
            align-items: center;
            justify-content: center;
            display: grid;
        }
    }

    @media (max-width: @screen-md) {
        .services-component {
            margin-right: 16px;
        }
        .service-item-component__background img {
            height: 100%;
        }
        .service-component-1 {
            .service-item-component__top {
                padding: 32px;
            }
        }
    }

    @media (max-width: 830px) {
        .link__description {
            font-size: 14px;
            line-height: 22px;
        }
        .service-item__title {
            font-size: 22px;
            line-height: 26px;
        }
    }

    @media (max-width: @screen-sm) {
        flex-direction: column;

        .service-item__title {
            font-size: 28px;
            line-height: 32px;
        }
        .link__description {
            font-size: 20px;
            line-height: 25px;
        }
        .services-component {
            margin-right: 0;
            margin-bottom: 32px;
            &:nth-child(1) { order: 2; }
            &:nth-child(2) { order: 1; }
            &:nth-child(3) {
                order: 3;
                margin-bottom: 0;
            }
        }
        .service-component-1 {
            min-height: 400px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    @media (max-width: 520px) {
        .service-component-1 {
            min-height: 260px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .service-item__title {
            font-size: 24px;
            line-height: 28px;
        }
        .link__description {
            font-size: 16px;
            line-height: 25px;
        }
    }
}

