.article {
    text-align: center;
}

.article__back--container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    .article__back {
        height: 20px;
        padding-bottom: 32px;
    }
}

.article-page-heading {
    margin-top: 56px;
    @media @to-large {
        margin-top: 32px;
    }
    @media @to-medium {
        margin: 24px 16px 0 16px;
    }
}

.article__content {
    .article__headline {
        .text-primary;
        font-family: @font-family-serif;
        font-size: 30px;
        font-weight: bold;
        line-height: 120%;

        @media @from-large {
            font-size: 46px;
        }

        margin: 0 0 10px 0;
        @media @from-xsmall {
            margin: 0 0 15px 0;
        }

        @media @from-large {
            margin: 0 0 20px 0;
        }
    }

    .article-breadcrumbs {
        display: flex;
        justify-content: center;
        .article-breadcrumbs_content {
            background-color: @color-white;
            padding: 8px 16px;
            margin-bottom: 32px;
            
            a {
                color: @color-nevada;
                border-bottom: none;
                font-size: 16px;
                font-weight: bold;
                &:hover {
                    color: @color-blue-stone
                }
                &::after {
                    content: "";
                    height: 5px;
                    width: 5px;
                    margin: 0 4px;
                    border-radius: 50%;
                    background-color: @color-nevada;
                    display: inline-block;
                    vertical-align: middle;
                }
                &:last-child {
                    &::after {
                        content: none;
                    }
                }
            }
        }
    }

    .article__tags {
        display: flex;
        justify-content: center;
        margin-bottom: 24px;
        a {
            border-bottom: none;
            padding: 0 8px;
            background-color: @color-oxford;
            border-radius: 2px;
            margin-right: 4px;
            font-size: 13px;
            color: @color-white;
            font-weight: bold;

            &:hover {
                background-color: @color-swamp;
            }
        }
    }

    .content-text;
    background-color: @site-background;
    max-width: 800px;
    padding: 20px;
    z-index: 2;
    margin: 0 auto;
    display: block;
    position: relative;
    text-align: left;

    @media @from-xsmall {
        padding: 30px;
        .article__has-illustration & {
            padding-top: 20px;
        }
    }

    @media @from-medium {
        padding: 30px 45px;
    }

    @media @from-large {
        padding: 40px 60px 35px;
        .article__has-illustration & {
            margin-top: 0;
            padding-top: 10px;
        }
    }

    .article__no-image:not(.article__has-illustration) & {
        margin-top: 0;
        padding-top: 0;
    }

    iframe,
    img {
        width: 100%;
    }
}

.article__image-container {
    img {
        width: 100%;
    }
}

.article.article__no-image:not(.article__has-illustration) {
    margin-top: 25px;
    .article__image-credits {
        display: none;
    }
    @media @from-medium {
        margin-top: 0;
    }
}

.article-page__content--width {
    max-width: 90ch;
    margin: 0 auto;

    @media @from-medium {
        padding: 0 96px;
    }
    @media @from-large {
        padding: 0 64px;
    }
}

.article-page__content {
    .article-page__content--width;
    @media @to-medium {
        margin: 0 16px;
    }

    h1, h2, h3, h4, h5 {
        .text-primary;
    }

    h2, h3, h4, h5 {
        margin-bottom: 8px;
    }
    >.content:not(:last-child) {
        .section-spacing;
    }

    .content {
        h2, h3 {
            @media @to-small {
                margin-top: 40px;
            }
            @media @from-small {
                margin-top: 56px;
            }
        }

        h2 + h3 {
            margin-top: 24px;
        }

        blockquote p:first-child {
            margin: 0 0 32px;
        }

        p {
            margin-bottom: 24px;

            &:last-child {
                margin-bottom: 0;
            }
            &:first-child {
                margin-top: 0;
            }
        }

        >p {
            margin-top: 24px;
        }

        p + ul {
            margin-top: -16px; // Move up ul appearing right after a p to offset newline spacing
        }

        ul {
            margin-bottom: 16px;
        }
    }

    .article__leadin {
        text-align: center;
        font-family: @font-family-serif-ingress;
        max-width: 75ch;
        .section-spacing-small;
        .text-large;
        .text-secondary;
    }

    div, p {
        .content-text;
    }

    hr {
        border-top: 2px solid @color-separator;
        margin: 0 0 15px 0;
    }

    .article__image-credits {
        color: @color-oxford;
        font-size: 14px;
        text-align: left;
        padding-top: 16px;
    }

    blockquote {
        .article-highlighted-text;
        padding: 0 0 36px;

        p {
            font-size: 21px;
            color: @color-blue-stone;
            margin-top: 0;

            @media @from-medium {
                font-size: 31px;
            }
        }
    }

    iframe {
        margin: 30px 0;
        width: 100%;
    }

    img {
        width: 100%;
        .section-spacing-small;
    }

    table {
        border-collapse: collapse;
        border: 1px solid @color-grey-03;

        .section-spacing-small;

        @media @to-large {
            width: 100%;
        }

        tr {
            border: 1px solid @color-grey-03;
        }

        tr:nth-child(odd) {
            td {
                background-color: @color-narvik;
            }
        }

        th, td {
            padding: 16px;
            background-color: @color-white;
            font-size: 14px;
            line-height: 110%;
            border: none;
        }

        th {
            font-weight: bold;
        }
    }

    a {
        overflow-wrap: break-word;
    }

    .article-highlighted-text {
        text-align: center;
        font-family: @font-family-serif-ingress;
        border-left: none;
        .section-spacing-small;
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
            font-size: 21px;
            color: @color-blue-stone;
            line-height: 160%;
            padding: 24px 0 8px;

            @media @from-medium {
                font-size: 31px;
            }
        }

        &:before {
            content: "";
            border-top: 1px solid @color-blue-stone;
            border-bottom: 3px solid @color-blue-stone;
            width: 30%;
            padding-top: 2px;
        }

        &:after {
            content: "";
            border-bottom: 1px solid @color-blue-stone;
            border-top: 3px solid @color-blue-stone;
            width: 30%;
            padding-bottom: 2px;
        }
    }
}
