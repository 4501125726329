:root {
	--font-size-h1: 2.125rem; //34px
	--font-size-h2: 1.625rem; //26px
	--font-size-h3: 1.250rem; //20px
	--font-size-h4: 1.125rem; //18px
	--font-size-ingress: 1.25rem; // 20px
	--font-size-p: 1rem; //16px
	--font-size-smaller: 0.875rem; //14px
	--font-size-small: 0.875rem; //14px

	@media @desktop {
		--font-size-h1: 3rem; //48px
		--font-size-h2: 2.125rem; // 34px
		--font-size-h3: 1.625rem; //26px
		--font-size-h4: 1.250rem; //20px
		--font-size-ingress: 1.5rem; //24px
		--font-size-p: 1.125rem; //18px
		--font-size-smaller: 1rem; //16px
	}
}


body {
	-webkit-font-smoothing: antialiased;
}

.headline-large {
	font-size: 36px;
	line-height: 43px;

	@media @from-medium {
		font-size: 48px;
		line-height: 58px;
	}
}

h1,
h2,
h3,
h4,
h5 {
	font-weight: bold;
	line-height: 120%;

	&.section-heading {
		text-align: center;
		position: relative;
		overflow: hidden;
		.margin-bottom-large;

		@media @from-medium {
			white-space: nowrap;
		}
	}
}

h1,
.headline {
	font-size: 26px;
	margin-bottom: 16px;
	margin-top: 16px;
	text-align: center;

	@media @from-large {
		font-size: 32px;
	}
}

h2 {
	font-size: 24px;
	margin-bottom: 10px;

	@media @from-large {
		font-size: 28px;
	}
}

h3 {
	font-size: 20px;

	@media @from-large {
		font-size: 22px;
	}
}

h4 {
	font-size: 16px;

	@media @from-large {
		font-size: 18px;
	}
}

h5,
.sub-headline {
	font-size: 14px;

	@media @from-large {
		font-size: 16px;
	}
}

p {
	color: @color-oxford;
	margin: 0 0 24px;
	font-size: 14px;
	line-height: 140%;

	@media @from-large {
		font-size: 16px;
		margin: 0 0 32px;
	}
}

.text-large {
	font-size: 18px;
	line-height: 160%;
	font-weight: normal;

	@media @from-large {
		font-size: 24px;
	}
}

.content-text {
	.text-secondary;
	font-size: 16px;
	line-height: 180%;
	font-weight: normal;

	@media @from-large {
		font-size: 18px;
	}
}

.body-text {
	font-size: 14px;
	line-height: 22px;

	@media @from-medium {
		font-size: 16px;
		line-height: 24px;
	}
}

.text-emphasized {
	font-weight: bold;
}

.text {
	font-size: 14px;
	line-height: 19px;
}