.cms-article-module, .content-search-result-list{
    .article-module__heading-separator {
        &:before,
        &:after {
            background-color: @color-nevada;
            content: "";
            display: inline-block;
            height: 1px;
            position: relative;
            vertical-align: middle;
            width: 50%;
        }
        &:before {
            right: 24px;
            margin-left: -50%;
        }
        &:after {
            left: 24px;
            margin-right: -50%;
        }
    }

    .article-module__link {
        display: inline-block;
        position: absolute;
        font-weight: normal;
        font-size: 14px;
        bottom: 1px;
        right: 10px;
        &:hover, &:focus {
            text-decoration: none;
        }
        &:active {
            font-weight: bold;
        }
    }

    .article-module__items {
        overflow: hidden;
        height: 100%;
        position: relative;
        width: 100%;

        &--title {
            .section-spacing;
        }
    }
}

.article-card {
    background-color: @color-white;
    width: 100%;
    text-align: center;
    vertical-align: top;

    &.hidden {
        display: none;
    }

    @media @to-small {
        display: block;
    }

    @media @from-xsmall {
        display: inline-block;
    }

    .article-card__image-container:hover + .article-card__info {
        h2 {
            border-bottom: 1px solid;
        }
    }

    .article-card__image-container {
        overflow: hidden;
        a {
            .link--no-border;

            img {
                width: 100%;
                object-fit: cover;
                aspect-ratio: 16 / 9;
            }
        }
    }

    .article-card__info {
        display: block;
        padding: 24px;
        min-height: 138px;

        @media @from-large {
            min-height: 176px;
        }
        &:hover,
        &:active {
            h2 {
                border-bottom: 1px solid;
            }
        }

        h2 {
            .text-primary;
            font-size: 22px;
            font-weight: bold;
            font-family: @font-family-serif;
            display: inline;
            overflow-wrap: anywhere;

            @media @from-medium {
                font-size: 24px;
            }
            @media @from-large {
                font-size: 28px;
            }
        }
    }

    .article-card__category {
        padding-bottom: 16px;
        color: @color-nevada;
        font-weight: bold;
        &.empty {
            height: 32px;
        }
    }
}

/***************   Special adjustments spread of articles   ***********/
.cms-article-module {
    .article-module {
        padding: 0;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        .article__content {
            @media @to-medium {
                padding: 0;
            }
        }

        .article-module__items {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            align-content: space-around;
            column-gap: 40px;
            row-gap: 56px;

            @media (@to-small) {
                row-gap: 16px;
            }
            @media @to-xsmall {
                flex-direction: column;
            }
        }

        .article-card {
            width: 290px;
            height: 375px;

            @media @to-xsmall {
                 width: 100%;
            }
        }
        & > :last-child {
            margin-bottom: 0;
        }
    }
    .category-home-page & {
        .section-spacing;
    }
}
