@font-face {
    font-family: 'Vinmonopolet Tittel';
    src: url('../fonts/VinmonopoletTittel-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Vinmonopolet Ingress';
    src: url('../fonts/VinmonopoletIngress-Medium.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/robotoFlex.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/robotoFlex.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
